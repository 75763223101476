import React from "react";
import Link from "gatsby-link"

import Hero from "../../common/hero";

const EscuelasVacaciones = ({ children }) => (
  <>
    <Hero
      texto="Escuelas de Vacaciones"
      subtexto="Navidad, Primavera y Verano"
    />
    <div className="container has-text-centered">
      <Link to={"verano2019"} className="button is-text is-large">Información Escuelas 2019</Link>
    </div>
    <div className="hero is-small">
      <div className="hero-body">
        <div className="container has-text-centered ">
          <div className="content is-large">
            <div className="columns is-centered">
              <div className="column is-three-fifths borde-puntos color-turquesa">
                <p>
                  Las escuelas estacionales de Aristas son diferenciadoras, por
                  su carácter lúdico, innovador y conciliador.
                </p>
                <p>
                  Más de una década gestionando actividades de este tipo en
                  colaboración con las AMPAS nos atesoran.
                </p>
                <p>
                  Nuestro equipo pedagógico, en continua evolución, desarrolla y
                  crea cada curso escolar nuevos e innovadores proyectos
                  educativos pensados por y para los niños.
                </p>
                <p>
                  Contamos con una amplia gama de servicios para conciliar la
                  vida laboral con las vacaciones de nuestos hijos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default EscuelasVacaciones;
